import axios from 'axios';
import axiosRetry from 'axios-retry';
import { objectToFormData } from 'object-to-formdata';
import Default from '../defaults';

export const Resources = {
  api: process.env.REACT_APP_API_URL,
  version: process.env.REACT_APP_API_VERSION,
  clientId: process.env.REACT_APP_API_CLIENT_ID,
  clientSecret: process.env.REACT_APP_API_CLIENT_SECRET,
  user: 'user',
  login: 'login',
  logout: 'logout',
  organisations: 'organisations',
  products: 'products',
  categories: 'categories',
  brands: 'brands',
  users: 'users',
  suppliers: 'suppliers',
  quotes: 'quotes',
  inventories: 'inventories',
  stores: 'stores',
  permissions: 'user/permissions',
  roles: 'roles',
  barcodes: 'products/barcodes',
  printers: 'printers',
  measurements: 'measurements',
  autocomplete: 'autocomplete',
  movements: 'movements',
  register: 'register',
  resend: 'resend',
  verify: 'verify',
  payments: 'payments',
  warehouses: 'warehouses',
  firebaseToken: 'firebase/token',
  alerts: 'places/products/alerts',
  places: 'places',
  goods: 'goods',
  foods: 'foods',
  sets: 'sets',
  translations: 'translations',
};

const payload = {
  baseURL: `${Resources.api}/v${Resources.version}/`,
  timeout: 30000,
};

const callRefreshToken = async refreshToken => axios.create(payload)
  .post(Resources.login, {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: Resources.clientId,
    client_secret: Resources.clientSecret,
  });

export const forgetTokens = () => {
  localStorage.removeItem(Default.tokenKey);
  localStorage.removeItem(Default.refreshKey);
  localStorage.removeItem(Default.expiryKey);
};

export const setLoginTokens = (response) => {
  if (response.data.access_token) {
    localStorage.setItem(Default.tokenKey, response.data.access_token);
    localStorage.setItem(Default.refreshKey, response.data.refresh_token);
    localStorage.setItem(
      Default.expiryKey,
      (response.data.expires_in * 1000) + new Date().getTime(),
    );

    return response.data.access_token;
  }

  return null;
};

const getToken = async () => {
  if (localStorage.getItem(Default.expiryKey) <= new Date().getTime()) {
    try {
      const refreshToken = localStorage.getItem(Default.refreshKey);
      if (refreshToken) {
        const response = await callRefreshToken(refreshToken);
        return setLoginTokens(response);
      }
    } catch (e) {
      forgetTokens();
    }
  }

  return localStorage.getItem(Default.tokenKey);
};

const Instance = axios.create(payload);
Instance.interceptors.request.use(async (config) => {
  if (config.url !== Resources.login) {
    const token = await getToken();
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
  }

  return config;
}, error => Promise.reject(error));
axiosRetry(Instance, { retries: 3 });

const toFormData = object => objectToFormData(
  object,
  {
    booleansAsIntegers: true,
    indices: true,
  },
);

export { Instance, toFormData };
